import logo from './images/logo.jpeg';
import fachada from './images/fachada.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="mt-2">
        <img src={logo} className="App-logo" alt="logo" /><br/>
        <img src={fachada} className="App-logo" alt="fachada" /><br/>
        <div className="mt-2">
           <p>Moda Comércial de tintas Ltda<br/>
            <i class="fas fa-phone"></i>{' '}(19) 3582-2324 / 3582-4212<br/>
            <i class="fab fa-whatsapp-square"></i>{' '}(19) 3582-4212<br/>
            <i class="fas fa-at"></i>{' '}<a href='mailto:contato@modatintas.com.br'>contato@modatintas.com.br</a><br/>
            <i class="fas fa-map-marker-alt"></i>{' '}Rua Inácio Ribeiro, 675 - Centro<br/>
            Santa Rita do Passa Quatro - SP</p>
         </div>
      </div>
    </div>
  );
}

export default App;
